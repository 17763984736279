import React, { Component } from 'react';
import Logo from '../../atoms/Logo/index';
import Menu from '../../atoms/Menu/index';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import get from 'lodash.get';
import './style.scss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { checkProps } from '../../../constants/globalFunctions';
import search from '../../../assets/images/search.svg';
import searchWhite from '../../../assets/images/search-white.svg';
import { useMediaQuery } from 'react-responsive';
import { mediaQueries } from '../../../constants/globalVariable';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ''
    };
    this.searchInput = React.createRef();
  }
  handleSearchClick = () => {
    const { searchTerm } = this.state;
    if (searchTerm.trim()) {
      const hostname = window.location.hostname;
      const baseUrl = hostname === 'localhost' 
        ? `http://${hostname}:3000/insights` 
        : `http://${hostname}/insights`;
      const searchUrl = `${baseUrl}?search=${encodeURIComponent(searchTerm)}`;
      window.location.href = searchUrl;
    }
  };
  handleSearchIconClick = () => {
    const hostname = window.location.hostname;
    const baseUrl = hostname === 'localhost' 
      ? `http://${hostname}:3000/insights` 
      : `http://${hostname}/insights`;
    window.location.href = baseUrl;
  };
  

  inputListener = (e) => {
    if (e.key === 'Enter') {
      this.handleSearchClick();
    }
  };
  searchToggle() {
    const header = $('#header');
    header.toggleClass('searchLight searchDark');
    
    const isDarkTheme = useMediaQuery({ query: '(prefers-color-scheme: dark)' });
    const searchIcon = isDarkTheme ? searchWhite : search;
    $('.search-img').attr('src', searchIcon);
  }
  
  render() {
    const componentId = get(this.props, 'params.ComponentId', '');
    const theme = get(this.props, 'sitecoreContext.route.fields.ShowSecondaryLogo.value', false) ? 'black' : 'white';
    const logo = get(this.props, 'sitecoreContext.route.fields.ShowSecondaryLogo.value')
      ? 'black'
      : 'white';
    const doPropsExist = checkProps(this.props);
    const context = this.props.sitecoreContext;

    const showSecondaryLogo = get(context, 'route.fields.ShowSecondaryLogo.value', false);
    return (
      <div id={componentId ? componentId : uuid()}>
        {doPropsExist ? (
          <Container id="header" className={`${logo}`}>
            <div className="header d-flex align-items-center">
              <Logo logoDetails={this.props} />
                <div className="form d-flex nav-bar-search">
                  <div className="search" onClick={this.handleSearchClick}>
                    <img className="search-img d-md-block d-none" src={search} alt="Search Icon" />
                    <img onClick={this.handleSearchIconClick} className="search-img-whit d-md-none block" src={showSecondaryLogo ? search : searchWhite} alt="Search Icon" />
                  </div>
                  <Form.Control
                    placeholder="Search"
                    onKeyDown={(e) => this.inputListener(e)}
                    onChange={(e) => this.setState({ searchTerm: e.target.value })}
                    ref={this.searchInput}
                    className="mx-2 border-0"
                  />
                </div>
              <Menu menuDetails={this.props} />
            </div>
          </Container>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withSitecoreContext()(Header);
